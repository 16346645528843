export const environment = {
    production: false,
    brandCode: 'nikugen',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyA8uETeMbx_4R2Ij6FFocn5Pofev1j0zlU',
        authDomain: 'mc-app-nikugen-dev.firebaseapp.com',
        projectId: 'mc-app-nikugen-dev',
        storageBucket: 'mc-app-nikugen-dev.appspot.com',
        messagingSenderId: '241633318671',
        appId: '1:241633318671:web:a5ae49db20320cd7f5d07a',
        measurementId: 'G-HRBVHHPCEG',
    },
    hostingBaseURL: 'https://app-dev.nikugen.jp',
    appScheme: 'nikugen-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id1574298255',
        android: 'https://play.google.com/store/apps/details?id=jp.nikugen.members',
    },
}
